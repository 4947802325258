import { ref } from "vue"
import GENERIC_VARIABLES from "../../config";
import Tr from "@/translations"


const getArticles = (brand = "KOMA TOOLS", subcategories = null, filters = null, novelties = false, top = false, limit = null, query = null, features = false) => {

    let subcategoriesParameter = ""
    let filtersParameter = ""

    // language, description, images, thumbnails, video, features, relacionados, similares, novelties, featured
    //let API = process.env.VUE_APP_ROOT_API+"articles?language="+Tr.currentLocale+"&description=false&images=false&thumbnails=false&video=false&features="+features+"&relacionados=false&similares=false&novelties="+novelties+"&featured="+top
    

    let API = process.env.VUE_APP_ROOT_API + "articles?language=" + Tr.currentLocale + 
    "&description=false&images=false&thumbnails=false&video=false&features=" + features + 
    "&relacionados=false&similares=false&novelties=" + novelties + "&featured=" + top +
    "&brand=" + encodeURIComponent("KOMA TOOLS");  

    if(limit) API = API+"&limit="+limit;
    if(query) API = API+"&query="+query;


    if(subcategories) {
        subcategories.forEach(subcategory => {
            subcategoriesParameter += '&subcategories[]='+subcategory
        })
        API = API + subcategoriesParameter
    }

    if(filters) {
        filters.forEach(filter => {
            filtersParameter += '&filters[]='+filter
        })
        API = API + filtersParameter
    }
    // console.log(API)



    const articles = ref([])
    const error = ref(null)

    const load = async () => {
        try {
            let response = await fetch(API)
            
            let data = await response.json()

            if(data.error) throw Error(data.message)
            if (data.code != "200") throw Error(data.message)
            
            articles.value = await data.results

        } catch (err) {
            error.value = err.message
        }

    }
    return { articles, error, load } 
}

export default getArticles
